import React from 'react'

const Footer = () => {
  return (
    <footer>
      <ul className="attribution">
        <li>&copy; 2019 Universal Music Group.</li>
        <li>
          <a href="http://privacypolicy.umusic.com/terms/">Terms Of Service</a>
        </li>
        <li>
          <a href="http://privacypolicy.umusic.com/">Privacy Policy</a>
        </li>
      </ul>
    </footer>
  )
}

export default Footer
