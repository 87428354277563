"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var DEFAULT_HEIGHT = 500;
var DEFAULT_WIDTH = 1600;

var Snow = function (_Component) {
  _inherits(Snow, _Component);

  function Snow(props, context) {
    _classCallCheck(this, Snow);

    var _this = _possibleConstructorReturn(this, (Snow.__proto__ || Object.getPrototypeOf(Snow)).call(this, props, context));

    _this.state = {
      intervalTracker: null,
      canvasCtx: null,
      height: props.height || window.innerHeight || DEFAULT_HEIGHT,
      width: props.width || window.innerWidth || DEFAULT_WIDTH
    };
    return _this;
  }

  _createClass(Snow, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      //canvas init
      var canvas = document.getElementsByClassName("react-snow-effect-canvas");
      var ctx = canvas[0].getContext("2d");

      this.setState({ canvasCtx: ctx });

      //canvas dimensions
      var W = this.state.width;
      var H = this.state.height;

      //snowflake particles
      var mp = 60; //max particles
      var particles = [];
      for (var i = 0; i < mp; i++) {
        particles.push({
          x: Math.random() * W, //x-coordinate
          y: Math.random() * H, //y-coordinate
          r: Math.random() * 4 + 1, //radius
          d: Math.random() * mp //density
        });
      }

      //Lets draw the flakes
      function draw() {
        ctx.clearRect(0, 0, W, H);

        ctx.fillStyle = "rgba(255, 255, 255, 0.8)";
        ctx.beginPath();
        for (var _i = 0; _i < mp; _i++) {
          var p = particles[_i];
          ctx.moveTo(p.x, p.y);
          ctx.arc(p.x, p.y, p.r, 0, Math.PI * 2, true);
        }
        ctx.fill();
        update();
      }

      //Function to move the snowflakes
      //angle will be an ongoing incremental flag. Sin and Cos functions will be applied to it to create vertical and horizontal movements of the flakes
      var angle = 0;

      function update() {
        angle += 0.01;
        for (var _i2 = 0; _i2 < mp; _i2++) {
          var p = particles[_i2];
          //Updating X and Y coordinates
          //We will add 1 to the cos function to prevent negative values which will lead flakes to move upwards
          //Every particle has its own density which can be used to make the downward movement different for each flake
          //Lets make it more random by adding in the radius
          p.y += Math.cos(angle + p.d) + 1 + p.r / 2;
          p.x += Math.sin(angle) * 2;

          //Sending flakes back from the top when it exits
          //Lets make it a bit more organic and let flakes enter from the left and right also.
          if (p.x > W + 5 || p.x < -5 || p.y > H) {
            if (_i2 % 3 > 0) //66.67% of the flakes
            {
              particles[_i2] = { x: Math.random() * W, y: -10, r: p.r, d: p.d };
            } else {
              //If the flake is exitting from the right
              if (Math.sin(angle) > 0) {
                //Enter from the left
                particles[_i2] = { x: -5, y: Math.random() * H, r: p.r, d: p.d };
              } else {
                //Enter from the right
                particles[_i2] = { x: W + 5, y: Math.random() * H, r: p.r, d: p.d };
              }
            }
          }
        }
      }

      this.setState({ intervalTracker: setInterval(draw, 33) });

      //animation loop
      // this.state.intervalTracker;
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      this.state.canvasCtx.clearRect(0, 0, this.state.width, this.state.height);
      clearInterval(this.state.intervalTracker);
    }
  }, {
    key: "render",
    value: function render() {
      var snowStyles = {
        margin: 0,
        padding: 0,
        pointerEvents: 'none',
        position: 'fixed',
        top: 0,
        zIndex: 1
      };

      return _react2.default.createElement("canvas", {
        className: "react-snow-effect-canvas",
        style: snowStyles,
        width: this.state.width,
        height: this.state.height
      });
    }
  }]);

  return Snow;
}(_react.Component);

exports.default = Snow;

module.exports = exports['default'];