import React from 'react'

import IconApple from '../images/dsp/apple.png'
import IconSpotify from '../images/dsp/spotify.png'
import IconAmazon from '../images/dsp/amazon.png'
import IconPandora from '../images/dsp/pandora.png'

const Links = () => {
  return (
    <div>
    <p className="links-title">Christmas Music Playlist</p>
      <ul className="links">
        <li className="apple">
          <a href="https://stream.lnk.to/ChristmasWe/applemusic" target="_blank">
            <img src={IconApple} />
          </a>
        </li>
        <li className="spotify">
          <a href="https://stream.lnk.to/ChristmasWe/spotify" target="_blank">
            <img src={IconSpotify} />
          </a>
        </li>
      </ul>
    </div>
  )
}

export default Links
