import React, { useState } from 'react'
import YouTube from 'react-youtube'
import { useSpring, animated } from 'react-spring'
import { Col, Row } from 'react-styled-flexboxgrid'

import PlayButton from '../images/player/play-button.png'
import PauseButton from '../images/player/pause-button.png'
import NextButton from '../images/player/forward-button.png'
import PreviousButton from '../images/player/back-buttton.png'

export default function Player({ playlist }) {
  const [player, setPlayer] = useState()
  const [isPlaying, setIsPlaying] = useState(false)
  const [title, setTitle] = useState()

  const onReady = e => {
    setPlayer(e.target)
  }

  const play = () => {
    player.playVideo()
  }

  const pause = () => {
    player.pauseVideo()
  }

  const previous = () => {
    player.previousVideo()
  }

  const next = () => {
    player.nextVideo()
  }

  const onPlay = () => {
    let data = player.getVideoData()
    setTitle(data.title)
    setIsPlaying(true)
  }

  const onPause = () => {
    setIsPlaying(false)
  }

  const titleStyle = useSpring({
    opacity: player ? 1 : 0,
  })

  const playlists = [
    {
      name: 'classic',
      id: 'PL7Sv7aQs2p0U6acfljozxXAFXKUd1El7g',
    },
    {
      name: 'motown',
      id: 'PL7Sv7aQs2p0V7TtwajuL7RpXx4KNrZ9Fq',
    },
    {
      name: 'pop',
      id: 'PL7Sv7aQs2p0U4_Ck_PkqKak28wKzft3iZ',
    },
  ]

  const playerOpts = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      listType: 'playlist',
      list: playlists[playlist].id,
      controls: 0,
      loop: 1,
      modestbranding: 1,
      playsinline: 1,
      showinfo: 0,
      disablekb: 1,
      enablejsapi: 1,
      iv_load_policy: 3,
      rel: 0,
    },
  }
  return (
    <>
      <YouTube
        className="yt-player"
        containerClassName="yt-wrapper"
        opts={playerOpts}
        onReady={onReady}
        onPlay={onPlay}
        onPause={onPause}
      />

      <div className="controls-desktop">
        <Row middle="xs">
          <Col xs={12} sm={2}>
            <animated.div style={titleStyle}>
              <img className="control-btn" src={PreviousButton} onClick={previous} />
              <img className="control-btn" src={NextButton} onClick={next} />
            </animated.div>
          </Col>
          <Col xs={12} sm={8}>
            <animated.div style={titleStyle} className="yt-title">
              {title}
            </animated.div>
          </Col>
          <Col xs={12} sm={2}>
            <animated.div style={titleStyle}>
              {!isPlaying && <img className="control-btn" src={PlayButton} onClick={play} />}
              {isPlaying && <img className="control-btn" src={PauseButton} onClick={pause} />}
            </animated.div>
          </Col>
        </Row>
      </div>

      <div className="controls-mobile">
        <Row middle="xs">
          <Col xs={6} sm={2}>
            <animated.div style={titleStyle}>
              <img className="control-btn" src={PreviousButton} onClick={previous} />
              <img className="control-btn" src={NextButton} onClick={next} />
            </animated.div>
          </Col>
          <Col xs={6} sm={2}>
            <animated.div style={titleStyle}>
              {!isPlaying && <img className="control-btn" src={PlayButton} onClick={play} />}
              {isPlaying && <img className="control-btn" src={PauseButton} onClick={pause} />}
            </animated.div>
          </Col>
          <Col xs={12} sm={8}>
            <animated.div style={titleStyle} className="yt-title">
              {title}
            </animated.div>
          </Col>
        </Row>
        <Row>
          <div class="sharethis-inline-share-buttons"></div>
        </Row>
      </div>
    </>
  )
}
