import React, { useEffect } from 'react'
import { useSpring, animated } from 'react-spring'

import { useMousePosition } from '../hooks/useMousePosition'

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const trans1 = (x, y) => `translate3d(${x / 40}px,${y / 40}px,0)`
const trans2 = (x, y) => `translate3d(${x / 100}px,${y / 100}px,0)`
const trans3 = (x, y) => `translate3d(${x / 200}px,${y / 200}px,0)`

export default function Parallax({ card1, card2, card3 }) {
  const position = useMousePosition()
  const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 10, tension: 550, friction: 140 } }))

  useEffect(() => {
    set({ xy: calc(position.x, position.y) })
  }, [position])

  return (
    <div className="parallax">
      <animated.div
        className="card card1"
        style={{ transform: props.xy.interpolate(trans1), backgroundImage: `url(${card1})` }}
      />
      <animated.div
        className="card card2"
        style={{ transform: props.xy.interpolate(trans2), backgroundImage: `url(${card2})` }}
      />
      <animated.div
        className="card card3"
        style={{ transform: props.xy.interpolate(trans3), backgroundImage: `url(${card3})` }}
      />
    </div>
  )
}
