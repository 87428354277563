import React from 'react'
import { Grid, Col, Row } from 'react-styled-flexboxgrid'
import { Link } from 'wouter'

import Parallax from '../components/Parallax'

import Title from '../images/logos/christmasmusictv.png'
import Background from '../images/background/home.jpg'

import ClassicTitle from '../images/menu/classic-christmas.png'
import MotownTitle from '../images/menu/motown-christmas.png'
import PopTitle from '../images/menu/pop-christmas.png'

import Card1 from '../images/parallax/home/characters.png'

const Home = () => (
  <div className="page" style={{ backgroundImage: `url(${Background})` }}>
    <Grid className="container home">
      <Row middle="xs">
        <Col xs={12} sm={6} smOffset={3}>
          <img className="page-title" src={Title} />
        </Col>
        <Col xs={12} sm={3} />
      </Row>
      <Row>
        <Col xs={12}>
          <div className="cta">CHOOSE A VIDEO PLAYLIST</div>
        </Col>
      </Row>
      <Row middle="xs">
        <Col xs={12} sm={4}>
          <Link className="menu-item" to="/classic">
            <img src={ClassicTitle} />
            <p>
             Videos for Frank Sinatra's "Jingle Bells," Dean Martin's "Let It Snow! Let It Snow! Let It
                  Snow!," Burl Ives' "Holly Jolly Christmas," Nat King Cole's "Christmas Song," Brenda Lee's
                  "Rockin Around The Christmas Tree" and many more are featured in this Classic
                  #ChristmasMusic playlist.
            </p>
          </Link>
        </Col>
        <Col xs={12} sm={4}>
          <Link className="menu-item" to="/motown">
            <img src={MotownTitle} />
            <p>
               Have a Soulful Christmas playing the #ChristmasMusic videos in this playlist including Jackson 5’s “I Saw Mommy Kissing Santa Claus,” The Temptations’ “Silent Night,” The Supremes’ “My Favorite Things,” Stevie Wonder’s “The Christmas Song,” Four Tops’ “Christmas Here With You” and many more.
            </p>
          </Link>
        </Col>
        <Col xs={12} sm={4}>
          <Link className="menu-item" to="/pop">
            <img src={PopTitle} />
            <p>
            Find all the best modern #ChristmasMusic videos in this playlist featuring Ariana Grande’s “Santa Tell Me,” Sam Smith’s “Have Yourself A Merry Little Christmas,” Justin Bieber’s “Mistletoe,” Jessie J’s “This Christmas Day,” Gwen Stefani’s “You Make It Fell Like Christmas” and many more.
            </p>
          </Link>
        </Col>
      </Row>
    </Grid>
    <div className="parallax-home">
      <Parallax card1={Card1} />
    </div>
  </div>
)

export default Home
