import React from 'react'
import { Grid, Col, Row } from 'react-styled-flexboxgrid'

import Player from '../components/Player'
import Links from '../components/Links'
import Parallax from '../components/Parallax'

import Title from '../images/logos/pop-christmas.png'
import Background from '../images/background/pop.jpg'

import Card1 from '../images/parallax/pop/Brenda_0.13_Side.png'
import Card2 from '../images/parallax/pop/Snowflake_1.png'
import Card3 from '../images/parallax/pop/Burl_0.13_Side.png'

const Pop = () => (
  <div className="page" style={{ backgroundImage: `url(${Background})` }}>
    <Grid className="container">
      <Row middle="xs">
        <Col xs={12} sm={6}>
          <img className="page-title" src={Title} />
        </Col>
        <Col xs={12} sm={6}>
          <Links />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Player playlist={2} />
        </Col>
      </Row>
    </Grid>
    <Parallax card1={Card1} card2={Card2} card3={Card3} />
  </div>
)

export default Pop
