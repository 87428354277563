import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { useSpring, useTransition, animated } from 'react-spring'
import { useLocation, Switch, Route } from 'wouter'
import { ThemeProvider } from 'styled-components'

import './styles.css'

import Menu from './components/Menu'
import Footer from './components/Footer'
import Snow from './components/Snow'

import Home from './pages/Home'
import Classic from './pages/Classic'
import Motown from './pages/Motown'
import Pop from './pages/Pop'

const theme = {
  flexboxgrid: {
    // Defaults
    gridSize: 12, // columns
    gutterWidth: 1, // rem
    outerMargin: 2, // rem
    mediaQuery: 'only screen',
    container: {
      sm: 46, // rem
      md: 55, // rem
      lg: 57, // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: 48, // em
      md: 57, // em
      lg: 59, // em
    },
  },
}

const Content = () => {
  const [location] = useLocation()
  const transitions = useTransition(location, null, {
    from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
  })

  return transitions.map(({ item: location, props, key }) => (
    <animated.div key={key} style={props}>
      <Switch location={location}>
        <Route path="/" component={Home} key={location} />
        <Route path="/classic" component={Classic} key={location} />
        <Route path="/motown" component={Motown} key={location} />
        <Route path="/pop" component={Pop} key={location} />
      </Switch>
    </animated.div>
  ))
}

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [headerHeight, setHeaderHeight] = useState(280)

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
    console.log(headerHeight)
  }

  const setHeight = height => {
    setHeaderHeight(height)
  }

  const pushProps = useSpring({
    y: isMenuOpen ? headerHeight : 0,
  })

  return (
    <>
      <div className="burger" onClick={toggleMenu}>
        <input type="checkbox" checked={isMenuOpen} />
        <span></span>
        <span></span>
        <span></span>
      </div>
      <Snow />
      <Menu isOpen={isMenuOpen} toggleMenu={toggleMenu} setHeight={setHeight} />
      <Footer />

      <animated.div style={{ transform: pushProps.y.interpolate(y => `translate3d(0,${y}px,0)`) }}>
        <ThemeProvider theme={theme}>
          <Content />
        </ThemeProvider>
      </animated.div>
    </>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))
