import React, { useRef, useEffect, useLayoutEffect } from 'react'
import { Link } from 'wouter'
import { Grid, Col, Row } from 'react-styled-flexboxgrid'
import { useSpring, animated } from 'react-spring'

import ClassicTitle from '../images/menu/classic-christmas.png'
import MotownTitle from '../images/menu/motown-christmas.png'
import PopTitle from '../images/menu/pop-christmas.png'

const Menu = props => {
  const menu = useRef()

  useLayoutEffect(() => {
    if (menu.current) {
      props.setHeight(menu.current.offsetHeight)
    }
  }, [])

  useEffect(() => {
    if (menu.current) {
      const setHeight = () => {
        console.log(menu.current.offsetHeight)
        props.setHeight(menu.current.offsetHeight)
      }

      window.addEventListener('resize', setHeight)
    }
  }, [])

  const styleProps = useSpring({
    y: props.isOpen ? 0 : -100,
  })

  return (
    <animated.header
      ref={menu}
      style={{ transform: styleProps.y.interpolate(y => `translate3d(0,${y}%,0)`) }}>
      <nav>
        <Grid>
          <Row>
            <Col xs={12}>
              <div className="cta">CHOOSE ANOTHER CHRISTMASMUSIC.TV PLAYLIST BELOW:</div>
            </Col>
          </Row>
          <Row middle="xs">
            <Col xs={12} sm={4}>
              <Link onClick={props.toggleMenu} className="menu-item" to="/classic">
                <img src={ClassicTitle} />
                <p>
                  Videos for Frank Sinatra's "Jingle Bells," Dean Martin's "Let It Snow! Let It Snow! Let It
                  Snow!," Burl Ives' "Holly Jolly Christmas," Nat King Cole's "Christmas Song," Brenda Lee's
                  "Rockin Around The Christmas Tree" and many more are featured in this Classic
                  #ChristmasMusic playlist.
                </p>
              </Link>
            </Col>
            <Col xs={12} sm={4}>
              <Link onClick={props.toggleMenu} className="menu-item" to="/motown">
                <img src={MotownTitle} />
                <p>
                  Have a Soulful Christmas playing the #ChristmasMusic videos in this playlist including Jackson 5’s “I Saw Mommy Kissing Santa Claus,” The Temptations’ “Silent Night,” The Supremes’ “My Favorite Things,” Stevie Wonder’s “The Christmas Song,” Four Tops’ “Christmas Here With You” and many more.
                </p>
              </Link>
            </Col>
            <Col xs={12} sm={4}>
              <Link onClick={props.toggleMenu} className="menu-item" to="/pop">
                <img src={PopTitle} />
                <p>
                  Find all the best modern #ChristmasMusic videos in this playlist featuring Ariana Grande’s “Santa Tell Me,” Sam Smith’s “Have Yourself A Merry Little Christmas,” Justin Bieber’s “Mistletoe,” Jessie J’s “This Christmas Day,” Gwen Stefani’s “You Make It Fell Like Christmas” and many more.
                </p>
              </Link>
            </Col>
          </Row>
        </Grid>
      </nav>
    </animated.header>
  )
}

export default Menu
